import React from 'react'
import { Hero, Container } from 'react-bulma-components'
import Media from 'react-media';
import { Link } from 'gatsby';

class Sect extends React.Component{
    render(){
        return(
            <div>
                <div className="sideL">
                <div className="sideText">
                    <p className="sideTextHead">{this.props.head}</p>
                    <p className="sideTextSide">{this.props.side} | <Link className="sideTextLink" to="/collections">Back to products</Link> </p>
                </div>
                    
                    <div className="sideLI">
                        <img src={this.props.image} className="sideLIimage" />
                        <div className="sideLItext">
                            <p  className="sideLItextFirst">{this.props.sideTitle}</p>
                            <p className="sideLItextSecond">{this.props.sideDescription}</p>
                            <a target="_blank" className="thatSectLink" href={this.props.link}><span className="source">Source</span></a>
                        </div>    
                    </div>
                </div>
            </div>
        );
    }
}

class SideLayout extends React.Component {
    render(){
        return(


            <Hero className="sideLayout" size='medium'>

<Media query="(max-width: 900px)">
          {matches =>
            matches ? (
                <div>
                    <div className="sideCont"></div>
                <div className="mobileSideCont">
                <p className="mobileSideTextHead">{this.props.head}</p>
                    <p className="mobileSideTextSide">{this.props.side}  | <Link className="sideTextLink" to="/collections">Back to products</Link> </p>
                        <img src={this.props.image} className="mobileSideLIimage" />
                        
                    <p  className="mobileSideLItextFirst">{this.props.sideTitle}</p>
                            <p className="mobileSideLItextSecond">{this.props.sideDescription}</p>
                            <a target="_blank" className="mobileThatSectLink" href={this.props.link}><span className="source">Source</span></a>
                </div>
            

</div>
                
            ) : (
                <div className="sideCont">
                <Sect link={this.props.link} image={this.props.image} head={this.props.head} side={this.props.side} sideTitle={this.props.sideTitle} sideDescription={this.props.sideDescription}></Sect>
            </div>


                
                
            

            )
          }
        </Media>
            
                </Hero>
            
        );
    }
}
export default SideLayout