import React from 'react'
import Layout from '../components/layout'
import SideLayout from '../components/sideLayout'
import Image from  "../../static/Avotile.jpg"
export default () => <Layout hover='product'>
 <SideLayout head="Pharmaceutical" side="Vaccines" image={Image} sideTitle="The results of a balanced breeding program" sideDescription="The Bovans Brown is a highly versatile 
and robust bird. Combined traits 
include high peak production, great laying persistency, and a ﬂat egg weight curve, resulting in top quality 
dark brown eggs. The Bovans Brown 
has excellent feed intake capacity and 
robustness so that it fully expresses 
genetic potential in multi-age and 
free range environments. This is an 
attractive looking bird that maintains 
great feather cover. The Bovans Brown 
is an ideal bird for the commercial 
egg producer looking for overall solid 
performance."></SideLayout>
</Layout>